/* eslint-disable camelcase */
/* eslint-disable no-shadow */
// @ts-nocheck
import { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth } from '../state/slices/appSlice';
import useInitiate from './useInitiate';
import useRiskRating from './useRiskRating';
import useCommonReducer from './useCommonReducer';
import useTransactionScreening from './useTransactionScreening';
/**
 * @function useInitialScreening
 * @description a custom hook which handles APIs related to Initial screening screen
 * @returns {object} object
 */
export default function useInitialScreening() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    getRiskParameters,
    riskParameters,
    industry,
    product,
    occupation,
    crrMandatoryParameterList,
  } = useInitiate();
  const { getTransactionParameters, channel, productType, transactionType } =
    useTransactionScreening();
  const {
    fetchRiskList,
    calculateRiskRating,
    riskList,
    riskValue,
    riskResult,
    setRiskList,
    setRiskValue,
    systemRisk,
    setSystemRisk,
    riskResponse,
  } = useRiskRating();
  const {
    dispatch,
    setInitialPageIndexInitialScreening,
    setInitialPageSizeInitialScreening,
  } = useCommonReducer();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sanctionsList, setSanctionsList] = useState([]);
  const [pepList, setPepList] = useState([]);
  const [amsList, setAmsList] = useState([]);
  const [legalList, setLegalList] = useState([]);
  const [corporateList, setCorporateList] = useState([]);
  const [legalDocument, setLegalDocument] = useState([]);
  const [fetchUsersPageCount, setFetchUsersPageCount] = useState(0);
  const [hasData, setHasData] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [disabled, setdisabled] = useState(true);
  const [cardDetails, setCardDetails] = useState({});
  const [analystsList, setAnalystsList] = useState([]);
  const [referralList, setReferralList] = useState([]);
  const [dedupeInformation, setDedupeInformation] = useState([]);
  const [dedupeParameter, setDedupeParameter] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [template, setTemplate] = useState([]);
  const fetchIdRef = useRef(0);
  const { user } = useSelector(selectAuth);

  /**
   * @function resetAllList
   * @description A function to reset all lists
   * @returns {undefined}
   * @example resetAllList()
   */
  const resetAllList = () => {
    setSanctionsList([]);
    setPepList([]);
    setAmsList([]);
    setLegalList([]);
    setCorporateList([]);
  };
  /**
   * @function fetchUserList
   * @description A function to fetch User List
   * @returns {Promise} returns an axios promise
   */
  const fetchUserList = () => {
    setIsLoading(true);
    axios
      .get(API_ENDPOINTS.INITIAL_SCREENING_ALL_ANALYST)
      .then((response) => {
        setAnalystsList(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchReferralsList
   * @description A function to fetch Referrals List
   * @returns {Promise} returns an axios promise
   */
  const fetchReferralsList = () => {
    setIsLoading(true);
    axios
      .get(API_ENDPOINTS.INITIAL_SCREENING_REFERRALS)
      .then((response) => {
        setReferralList(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchUserList();
    fetchReferralsList();
  }, []);
  /**
   * @function fetchUsers
   * @description A function to implement pagination
   * @returns {Promise} returns an axios promise
   */
  const fetchUsers = useCallback(
    async ({
      pageSize,
      pageIndex,
      query,
      orderBy,
      status,
      days,
      checkerLevel,
      formData,
      alertList,
      alert,
      bookmark,
      team_type,
      channel,
      product,
      transaction_type,
      bookmarked,
    }) => {
      // Give this fetch an ID
      fetchIdRef.current += 1;
      const fetchId = fetchIdRef.current;

      // Your server could send back total page count.
      setIsLoading(true);

      try {
        const response = await axios.get(
          API_ENDPOINTS.INITIAL_SCREENING_ALERT_LIST,
          {
            params: {
              page: pageIndex + 1,
              screen: 'initial',
              ...(pageSize ? { pagesize: pageSize } : {}),
              query: query?.trim(),
              orderBy,
              checkerLevel,
              status,
              days,
              ...formData,
              alert: alert || '',
              alert_list: alertList ? alertList.join().toLowerCase() : '',
              ...(!bookmarked && {
                bookmark: bookmark !== '' ? bookmark : false,
              }),
              team_type,
              channel,
              product,
              transaction_type,
            },
            loader: !query?.length,
          }
        );

        setIsLoading(false);

        if (fetchId === fetchIdRef.current) {
          setUserList(response.data);
          setTotalUsers(response.headers['x-total-count']);
          setFetchUsersPageCount(
            Math.ceil(response.headers['x-total-count'] / pageSize)
          );

          // Check if the last page is empty, and move to the previous page if needed
          if (response?.data?.length === 0 && pageIndex > 0) {
            const newPageIndex = Math.max(pageIndex - 1, 0);
            dispatch(setInitialPageSizeInitialScreening(pageSize));
            dispatch(setInitialPageIndexInitialScreening(newPageIndex));
            return fetchUsers({
              pageSize,
              pageIndex: newPageIndex,
              query,
              orderBy,
              status,
              days,
              checkerLevel,
              formData,
              alertList,
              alert,
              team_type,
            });
          }

          if (!Number(response.headers['x-total-count'])) {
            setHasData(false);
          }
        }

        return response;
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    },
    []
  );

  /**
   * @function fetchCardData
   * @description A function to fetch Card Data
   * @returns {Promise} returns an axios promise
   */
  const fetchCardData = (queryParams) => {
    setIsLoading(true);
    resetAllList();
    return axios
      .get(
        `${API_ENDPOINTS.INITIAL_SCREENING_CARD_DETAILS}${queryParams.search_id}/`
      )
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            setCardDetails(response?.data);
          }
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function handleReview
   * @description A function to fetch review details
   * @returns {Promise} returns an axios promise
   */
  const handleReview = (queryParams) => {
    setIsLoading(true);
    resetAllList();
    return axios
      .post(`${API_ENDPOINTS.REVIEW_DETAILS}${queryParams.search_id}/`)
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            enqueueSnackbar(response?.data?.message, {
              variant: 'success',
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        },
        (response) => {
          setIsLoading(false);
          enqueueSnackbar(response?.response?.data?.message, {
            variant: 'error',
          });
        }
      );
  };
  /**
   * @function moveIsToOm
   * @description A function to move data from initial screening to ongoing monitoring
   * @returns {Promise} returns an axios promise
   */
  const moveIsToOm = (data) => {
    return axios.post(API_ENDPOINTS.MOVE_IS_TO_OM, data).then(
      () => {
        setIsLoading(false);
        enqueueSnackbar('Moved Successfully.', {
          variant: 'success',
        });
      },
      () => {
        setIsLoading(false);
      }
    );
  };
  /**
   * @function updateUser
   * @description A function to update User details
   * @returns {Promise} returns an axios promise
   */
  const updateUser = (data) => {
    setIsLoading(true);
    axios
      .patch(`${API_ENDPOINTS.INITIAL_SCREENING_CARD_DETAILS}${data.id}/`, data)
      .then(
        (response) => {
          setIsLoading(false);
          if (response.status === 200) {
            setdisabled(!disabled);
            enqueueSnackbar(response.data.Msg, {
              variant: 'success',
            });
          }
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function fetchSanctionsList
   * @description A function to fetch Sanctions List
   * @returns {Promise} returns an axios promise
   */
  const fetchSanctionsList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.INITIAL_SCREENING_CARD_DETAILS}${queryParams.id}/sanction`
      )
      .then((response) => {
        setSanctionsList(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchPepList
   * @description A function to fetch Pep List
   * @returns {Promise} returns an axios promise
   */
  const fetchPepList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.INITIAL_SCREENING_CARD_DETAILS}${queryParams.id}/pep`
      )
      .then((response) => {
        setIsLoading(false);
        setPepList([...response.data]);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchAmsList
   * @description A function to fetch Ams List
   * @returns {Promise} returns an axios promise
   */
  const fetchAmsList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.INITIAL_SCREENING_CARD_DETAILS}${queryParams.id}/ams`
      )
      .then((response) => {
        setAmsList([...response.data]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchLegalList
   * @description A function to fetch legal List
   * @returns {Promise} returns an axios promise
   */
  const fetchLegalList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.INITIAL_SCREENING_CARD_DETAILS}${queryParams.id}/legal`
      )
      .then((response) => {
        setLegalList([...response.data]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchCorporateList
   * @description A function to fetch corporate List
   * @returns {Promise} returns an axios promise
   */
  const fetchCorporateList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.INITIAL_SCREENING_CARD_DETAILS}${queryParams.id}/corporate`
      )
      .then((response) => {
        setCorporateList([...response.data]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchLegalDocument
   * @description A function to fetch legal document
   * @returns {Promise} returns an axios promise
   */
  const fetchLegalDocument = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.ONGOING_SCREENING_SUMMARY_LEGAL_DOCUMENT}${queryParams.tid}`
      )
      .then((response) => {
        setIsLoading(false);
        setLegalDocument(response?.data?.legal_documents);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function updateListsRow
   * @description A function to update Lists Row
   * @returns {Promise} returns an axios promise
   */
  const updateListsRow = ({ index, value }, listType) => {
    switch (listType) {
      case 'sanctions':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_result[index].status = value;
          return { ...updatedList };
        });
        break;
      case 'sanctionsCountry':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_country_result[index].status = value;
          return { ...updatedList };
        });
        break;
      case 'pep':
        setPepList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'ams':
        setAmsList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'legal':
        setLegalList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'corporate':
        setCorporateList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'risk':
        setRiskList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].classification = value;
          return [...updatedList];
        });
        break;
      default:
    }
  };
  const updateAllListsRow = (listType, mode) => {
    switch (listType) {
      case 'sanctions':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_result = updatedList.sanction_result.map(
            (item) => ({
              ...item,
              status: mode ? 'selected' : mode === null ? false : 'notSelected',
            })
          );
          return { ...updatedList };
        });
        break;
      case 'sanctionsCountry':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_country_result =
            updatedList.sanction_country_result.map((item) => ({
              ...item,
              status: mode ? 'selected' : mode === null ? false : 'notSelected',
            }));
          return { ...updatedList };
        });
        break;
      case 'pep':
        setPepList((prevState) => {
          const updatedList = prevState.map((item) => ({
            ...item,
            status: mode ? 'selected' : mode === null ? false : 'notSelected',
          }));
          return updatedList;
        });
        break;
      case 'ams':
        setAmsList((prevState) => {
          const updatedList = prevState.map((item) => ({
            ...item,
            status: mode ? 'selected' : mode === null ? false : 'notSelected',
          }));
          return updatedList;
        });
        break;
      case 'legal':
        setLegalList((prevState) => {
          const updatedList = prevState.map((item) => ({
            ...item,
            status: mode ? 'selected' : mode === null ? false : 'notSelected',
          }));
          return updatedList;
        });
        break;
      case 'corporate':
        setCorporateList((prevState) => {
          const updatedList = prevState.map((item) => ({
            ...item,
            status: mode ? 'selected' : mode === null ? false : 'notSelected',
          }));
          return updatedList;
        });
        break;
      case 'risk':
        setRiskList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].classification = value;
          return [...updatedList];
        });
        break;
      default:
    }
  };
  /**
   * @function updateListsCommentRow
   * @description A function for updating comments from Lists Row
   * @returns {Promise} returns an axios promise
   */
  const updateListsCommentRow = ({ index, value }, listType) => {
    switch (listType) {
      case 'sanctions':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_result[index].comments = value;
          return { ...updatedList };
        });
        break;
      case 'sanctionsCountry':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_country_result[index].comments = value;
          return { ...updatedList };
        });
        break;
      case 'pep':
        setPepList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      case 'ams':
        setAmsList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      case 'legal':
        setLegalList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      case 'corporate':
        setCorporateList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      default:
    }
  };

  function getObjectIndexById(array, id) {
    return array?.findIndex((obj) => obj?.id === id);
  }
  const updateAttachmentInCommentRow = (
    fileDetails,
    URL,
    listType,
    operationType = 'add'
  ) => {
    switch (listType) {
      case 'sanctionList':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          const index = getObjectIndexById(
            sanctionsList?.sanction_result,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList.sanction_result[index].attached_file = fileDetails;
            updatedList.sanction_result[index].attached_file_url = URL;
          } else {
            updatedList.sanction_result[index].attached_file = '';
            updatedList.sanction_result[index].attached_file_url = URL;
          }

          return { ...updatedList };
        });
        break;
      case 'sanctionsCountry':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          const index = getObjectIndexById(
            sanctionsList?.sanction_country_result,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList.sanction_country_result[index].attached_file =
              fileDetails;
            updatedList.sanction_country_result[index].attached_file_url = URL;
          } else {
            updatedList.sanction_country_result[index].attached_file = '';
            updatedList.sanction_country_result[index].attached_file_url = URL;
          }

          return { ...updatedList };
        });
        break;
      case 'pepList':
        setPepList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            pepList,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }

          return [...updatedList];
        });
        break;
      case 'amsList':
        setAmsList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            amsList,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }
          return [...prevState];
        });
        break;
      case 'legal':
        setLegalList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            prevState,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }
          return [...updatedList];
        });
        break;
      case 'corporate':
        setCorporateList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            prevState,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }
          return [...updatedList];
        });
        break;
      default:
    }
  };
  /**
   * @function submitSummaryVerdict
   * @description A function to submit Summary Verdict
   * @returns {Promise} returns an axios promise
   */
  const submitSummaryVerdict = (data) => {
    setIsLoading(true);
    const payload = {
      remarks: data.remarks,
      status: (data.verdict?.toLowerCase())[0] === 'a' ? 'a' : 'r',
      results: {
        ams_result: data.ams_result,
        pep_result: data.pep_result,
        sanction_result: data.sanction_result,
        sanction_country_result: data.sanction_country_result,
        legal_result: data.legal_result,
        corporate_result: data.corporate_result,
        ...(data?.risk
          ? {
              risk_rating_result: data?.risk_rating_result,
              risk_type: data?.risk_type,
              risk_rating: data?.risk_rating,
            }
          : {}),
      },
      attachment: data?.attachment,
      move_search_to_om: data?.move_search_to_om,
    };
    return axios
      .post(
        `${API_ENDPOINTS.INITIAL_SCREENING_SUMMARY_VERDICT}${data.id}/`,
        payload
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Verdict submitted successfully.', {
            variant: 'success',
          });
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function submitRequestReferral
   * @description A function to submit Request Referral
   * @returns {Promise} returns an axios promise
   */
  const submitRequestReferral = (data) => {
    setIsLoading(true);
    const payload = {
      description: data.remarks,
      requested_to: data.analystName,
      attachment: data?.attachment,
      results: {
        sanction_result: data.sanction_result,
        pep_result: data.pep_result,
        ams_result: data.ams_result,
        corporate_result: data.corporate_result,
        legal_result: data.legal_result,
        sanction_country_result: data?.sanction_country_result,
        risk_rating_result: data?.risk_rating_result,
      },
    };
    return axios
      .post(
        `${API_ENDPOINTS.INITIAL_SCREENING_REQUEST_ANALYST}${data.id}/`,
        payload
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Refer sent successfully.', {
            variant: 'success',
          });
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function submitReferral
   * @description A function submit Referral
   * @returns {Promise} returns an axios promise
   */
  const submitReferral = (data) => {
    setIsLoading(true);
    const payload = {
      comments: data.remarks,
      attachment: data?.attachment,
    };
    return axios
      .post(
        `${API_ENDPOINTS.INITIAL_SCREENING_REQUEST_ANALYST}${data.id}/`,
        payload
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Refer sent successfully.', {
            variant: 'success',
          });
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function fetchDedupeParameter
   * @description function for fetching deduplication parameters
   * @returns {Promise} returns an axios promise
   */
  const fetchDedupeParameter = () => {
    setIsLoading(true);
    axios
      .get(API_ENDPOINTS.DEDUPLICATION_PARAMETER)
      .then((response) => {
        setDedupeParameter(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchDedupeInformation
   * @description function for fetching deduplication information
   * @returns {Promise} returns an axios promise
   */
  const fetchDedupeInformation = (data) => {
    setIsLoading(true);
    axios
      .get(API_ENDPOINTS.DEDUPLICATE_INFORMATION, { params: data })
      .then((response) => {
        setDedupeInformation(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function updateDedupeInformation
   * @description function for updating deduplication information
   * @returns {Promise} returns an axios promise
   */
  const updateDedupeInformation = (data) => {
    const body = {
      ...data,
    };

    return axios.post(API_ENDPOINTS.DEDUPLICATE_INFORMATION, body).then(
      (response) => {
        if (response) {
          enqueueSnackbar(
            'Deduplication for the selected searches has been successfully completed.',
            {
              variant: 'success',
            }
          );
        }
      },
      () => {
        setIsLoading(false);
      }
    );
  };
  const addToBookmark = (data) => {
    const body = {
      ...data,
    };

    return axios.patch(API_ENDPOINTS.INITIAL_ONGOING_BOOKMARK, body).then(
      (response) => {
        if (response) {
          // enqueueSnackbar(
          //   'Deduplication for the selected searches has been successfully completed.',
          //   {
          //     variant: 'success',
          //   }
          // );
        }
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const assignToSelf = (data) => {
    const body = {
      ...data,
    };

    return axios
      .post(API_ENDPOINTS.INITIAL_ONGOING_COMMON_SEARCHES_ASSIGN, body)
      .then(
        (response) => {
          if (response) {
            enqueueSnackbar('Seaches assigned to you successfully', {
              variant: 'success',
            });
          }
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  const uploadAttachedFiles = (payload) => {
    setIsLoading(true);
    return axios
      .post(API_ENDPOINTS.GET_BULK_PRESIGNED_URL, payload)
      .then((res) => {
        setIsLoading(false);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteFile = ({ id, row, type }) => {
    const data = { filename: `${id}*${row?.attached_file}` };
    return axios
      .delete(`${API_ENDPOINTS.DELETE_FILE}`, { params: data })
      .then((res) => {
        updateAttachmentInCommentRow(
          `${id}*${row?.attached_file}`,
          '',
          type,
          'delete'
        );
        return res;
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.error, {
          variant: 'error',
        });
        throw error;
      });
  };
  const uploadFileOns3 = (uploadUrl, file) => {
    return axios
      .put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };
  /**
   * @function fetchTemplateList
   * @description A function to fetch team data
   * @returns {Promise} returns an axios promise
   */
  const fetchTemplateList = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.SAR_CONFIGURATION).then(
      (response) => {
        setIsLoading(false);
        setTemplateList(response?.data);
      },
      (e) => {
        setIsLoading(false);
      }
    );
  };

  /**
   * @function fetchTemplateList
   * @description A function to fetch team data
   * @returns {Promise} returns an axios promise
   */
  const fetchTemplate = (data) => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.GET_TEMPLATE, { params: data }).then(
      (response) => {
        setIsLoading(false);
        setTemplate(response?.data);
      },
      (e) => {
        setIsLoading(false);
      }
    );
  };

  return {
    user,
    fetchUsers,
    userList,
    setUserList,
    updateUser,
    fetchSanctionsList,
    fetchPepList,
    fetchAmsList,
    fetchLegalList,
    fetchCorporateList,
    sanctionsList,
    pepList,
    amsList,
    legalList,
    corporateList,
    legalDocument,
    fetchLegalDocument,
    updateListsRow,
    updateAllListsRow,
    updateListsCommentRow,
    isLoading,
    setRiskList,
    submitSummaryVerdict,
    fetchUsersPageCount,
    totalUsers,
    disabled,
    setdisabled,
    fetchCardData,
    cardDetails,
    setCardDetails,
    analystsList,
    fetchReferralsList,
    submitReferral,
    submitRequestReferral,
    referralList,
    hasData,
    setHasData,
    fetchDedupeInformation,
    dedupeInformation,
    updateDedupeInformation,
    setDedupeInformation,
    fetchDedupeParameter,
    dedupeParameter,
    riskList,
    riskValue,
    riskResult,
    setRiskValue,
    fetchRiskList,
    calculateRiskRating,
    getRiskParameters,
    riskParameters,
    industry,
    product,
    occupation,
    systemRisk,
    setSystemRisk,
    riskResponse,
    crrMandatoryParameterList,
    addToBookmark,
    moveIsToOm,
    assignToSelf,
    getTransactionParameters,
    channel,
    productType,
    transactionType,
    uploadAttachedFiles,
    updateAttachmentInCommentRow,
    deleteFile,
    uploadFileOns3,
    handleReview,
    fetchTemplateList,
    templateList,
    fetchTemplate,
    template,
  };
}
